import { getSiaActionTypes, getOrgChart, getSiaWorkingModes, getFestivities, getAnomalyTypes, getAssistanceStatuses, getUsers, getUserContracts, getUserRole, getSiaActionMonthlyPage } from '../helpers/Fetch';
import * as Constants from '../constants/AppConstants';
import { IActionTypeResponse } from "../interfaces/IActionTypeResponse";
import { IActionType } from "../interfaces/IActionType";
import { IOrgChartUser } from '../interfaces/IOrgChartUser';
import { IWorkingMode } from '../interfaces/IWorkingMode';
import { IWorkingModeResponse } from '../interfaces/IWorkingModeResponse';
import { IFestivityResponse } from '../interfaces/IFestivityResponse';
import { IAssistanceStatus } from '../interfaces/IAssistanceStatus';
import { IFestivity } from '../interfaces/IFestivity';
import { IAnomalyType } from '../interfaces/IAnomalyType';
import { IAnomalyTypeResponse } from '../interfaces/IAnomalyTypeResponse';
import { IAssistanceStatusResponse } from '../interfaces/IAssistanceStatusResponse';
import { findUserByEmailRecursive, getClientSideToken } from '../helpers/SiaHelper';
import { IUser } from '../interfaces/IUser';
import { UserType } from '../constants/AppEnum';
import { IEmployee } from '../interfaces/IEmployee';
import { ITimesheetAction } from '../interfaces/ITimesheetAction';
import moment from 'moment';
import { Resize20Regular } from '@fluentui/react-icons';


/* SET COOKIES WITH COMMON SHARED VALUES */
const expiration: number = 720; //dopo quanti minuti il valore non è più valido 

const setItem = (keyName: string, keyValue: string, useTtl: boolean = true) => {
    const data = {
        value: keyValue,
        ttl: useTtl ? moment(Date.now()).add(expiration, 'minutes') : null //(time to live)
    }
    localStorage.setItem(keyName, JSON.stringify(data));
};

const getItem = (keyName: string): string | null => {
    const data = localStorage.getItem(keyName);
    if (!data) {
        return null;
    }

    const item = JSON.parse(data);

    if (item.ttl === null || moment(Date.now()).isAfter(moment(item.ttl))) {
        localStorage.removeItem(keyName);
        return null;
    }
    return item.value;
}

export const setApplicationCookies = {
    clearSession: () => {
        localStorage.clear();
    },
    setActionTypesCookie: async (calendar: boolean = false): Promise<Array<IActionType>> => {
        // ACTION TYPES
        let actionTypes: Array<IActionType> = [] as Array<IActionType>;
        const itemName: string = calendar ? `${Constants.ACTION_TYPES_COOKIE}_CALENDAR` : `${Constants.ACTION_TYPES_COOKIE}`
        let actionTypesCookie = getItem(itemName);
        if (actionTypesCookie && actionTypesCookie !== '') {
            actionTypes = JSON.parse(actionTypesCookie) as Array<IActionType>;
        }
        else {
            const actionTypesResponse: IActionTypeResponse = await getSiaActionTypes(null, calendar);
            if (actionTypesResponse && actionTypesResponse.actions) {
                actionTypes = actionTypesResponse.actions as Array<IActionType>;
                setItem(itemName, JSON.stringify(actionTypes));
            }
        }
        return actionTypes;
    },

    setStaffQueryDataCookie: (date: Date) => {
        setItem('tmpDate', date.toISOString());
    },

    setActionTypesMonthlyPageCookie: async (): Promise<Array<IActionType>> => {
        // ACTION TYPES
        var actionTypes: Array<IActionType> = [] as Array<IActionType>;
        const itemName: string = Constants.ACTION_TYPES_MONTHLY_PAGE_COOKIE;
        let actionTypesCookie = getItem(itemName);
        if (actionTypesCookie && actionTypesCookie !== '') {
            actionTypes = JSON.parse(actionTypesCookie) as Array<IActionType>;
        }
        else {
            var actionTypesResponse: IActionTypeResponse = await getSiaActionMonthlyPage();
            if (actionTypesResponse && actionTypesResponse.actions) {
                actionTypes = actionTypesResponse.actions as Array<IActionType>;
                setItem(itemName, JSON.stringify(actionTypes));
            }
        }
        return actionTypes;
    },

    setUserToken: async (refresh: boolean | null): Promise<string> => {
        var token;
        if (refresh !== null && refresh === false) {
            token = getItem(Constants.USER_TOKEN_COOKIE);
        }
        if (!token || token === '') {
            token = await getClientSideToken();
            setItem(Constants.USER_TOKEN_COOKIE, token);
            // const roleResponse = await getUserRole();
            // const role: UserType = roleResponse.status === 1 ? roleResponse.payload as UserType : UserType.Unknown;
            // //const role = getRoleFromToken(token);           
            // setItem(Constants.USER_TYPE_COOKIE, role);
        }
        return token;
    },

    setContractNotFoundCookie: () => {
        setItem(Constants.CONTRACT_NOT_FOUND_COOKIE, "true");
    },

    setOrgChartCookie: async (email: string | null | undefined, startDate?: Date, endDate?: Date): Promise<IOrgChartUser> => {
        // ORG CHART

        let keySuffix = (startDate !== undefined && endDate !== undefined) ? `${moment(startDate).format("DDMMYYYY")}_${moment(endDate).format("DDMMYYYY")}` : "";

        let orgChartCookie = getItem(keySuffix !== '' ? `${Constants.ORG_CHART_COOKIE}_${keySuffix}` : Constants.ORG_CHART_COOKIE);
                if (orgChartCookie && orgChartCookie !== '') {
            const result = JSON.parse(orgChartCookie) as IOrgChartUser;
            if (result.startDate !== undefined && result.endDate !== undefined){
                result.startDate = new Date(result.startDate);
                result.endDate = new Date(result.endDate);
            }
            //Leggo dallo store solo se placesOfWork � valorizzato, questo perch� la prima volta che viene eseguito il codice dopo il rilascio del nuovo campo
            //sul FE c'� in cache un orgChart senza questo valore e questo genera un errore, cos� facendo 'forziamo' la chiamata alle API per avere il campo valorizzato
            if (result.placesOfWork !== undefined && result.placesOfWork !== null && result.placesOfWork !== '') {
                return result;
            }   
        }
        var orgChart: IOrgChartUser = await getOrgChart(email, startDate, endDate) as IOrgChartUser;

        //L'oggetto orgChart ritornato dalla chiamata alle API ritorna una gerarchia di utenti, il nodo root è sempre il responsabile dell'utente (se c'è)
        //e quindi l'oggetto che identifica l'utente nella maggior parte dei casi (quasi tutti hanno un responsabile/manager) non è il nodo root, ma è il primo 
        //presente nella proprietà users. Per questo devo cercarlo per email

        const currentUserOrgChart: IOrgChartUser = findUserByEmailRecursive(email, orgChart);
        if (startDate !== undefined && endDate !== undefined){
            currentUserOrgChart.startDate = startDate;
            currentUserOrgChart.endDate = endDate;
        }
        //setItem(Constants.ORG_CHART_COOKIE,JSON.stringify(orgChart));       
        setItem(keySuffix !== '' ? `${Constants.ORG_CHART_COOKIE}_${keySuffix}` : Constants.ORG_CHART_COOKIE, JSON.stringify(currentUserOrgChart));
        return currentUserOrgChart;
    },

    setWorkingModesCookie: async (): Promise<Array<IWorkingMode>> => {
        // ORG CHART
        var workingModes: Array<IWorkingMode> = {} as Array<IWorkingMode>;
        let workingModesCookie = getItem(Constants.WORKING_MODES_COOKIE);
        if (workingModesCookie && workingModesCookie !== '') {
            workingModes = JSON.parse(workingModesCookie) as Array<IWorkingMode>;
        }
        else {
            let workingModesResponse = await getSiaWorkingModes() as IWorkingModeResponse;
            if (workingModesResponse && workingModesResponse.workingModes && workingModesResponse.workingModes.length > 0) {
                workingModes = workingModesResponse.workingModes as Array<IWorkingMode>;
                setItem(Constants.WORKING_MODES_COOKIE, JSON.stringify(workingModes), false);
            }
        }
        return workingModes;
    },

    setCurrentWorkingModeCookie: async (idWorkingMode: number | null): Promise<number | null> => {
        // CURRENT WORKING MODE
        setItem(Constants.CURRENT_WORKING_MODE_COOKIE, idWorkingMode === null ? "null" : idWorkingMode.toString());
        return idWorkingMode;
    },

    setSelectedJobCookie: async (id: number): Promise<number> => {
        // EMPJOB SELECTED
        setItem(Constants.EMPJOB_SELECTED_COOKIE, id.toString());
        return id;
    },

    setFestivitiesCookie: async (placesOfWork: string, year?: number): Promise<Array<IFestivity>> => {
        // FESTIVITIES
        if (year === null || year === undefined) {
            year = new Date().getFullYear();
        }
        //var festivities: Array<IFestivity> = {} as Array<IFestivity>;
        var festivities: IFestivity[] = [];
        try {
            let festivitiesCookie = getItem(`${Constants.FESTIVITIES_COOKIE}_${placesOfWork}_${year}`);
            if (festivitiesCookie && festivitiesCookie !== '') {
                festivities = JSON.parse(festivitiesCookie) as Array<IFestivity>;
            }
            else {
                let festivitiesResponse = await getFestivities(placesOfWork, year) as IFestivityResponse;
                if (festivitiesResponse && festivitiesResponse.festivities && festivitiesResponse.festivities.length > 0) {
                    festivities = festivitiesResponse.festivities as Array<IFestivity>;
                    setItem(`${Constants.FESTIVITIES_COOKIE}_${placesOfWork}_${year}`, JSON.stringify(festivities));
                }
            }
        } catch (error) {
            throw new Error(`Errore in caricamento Festivities : ${(error as Error).message}`);
        }

        return festivities;
    },


    setAnomalyTypesCookie: async (): Promise<Array<IAnomalyType>> => {

        //ANOMALY TYPES
        var anomalyTypes: Array<IAnomalyType> = {} as Array<IAnomalyType>;
        try {
            let anomaliesCookie = getItem(Constants.ANOMALY_TYPES_COOKIE);
            if (anomaliesCookie && anomaliesCookie !== '') {
                anomalyTypes = JSON.parse(anomaliesCookie) as Array<IAnomalyType>;
            }
            else {
                let anomaliesResponse = await getAnomalyTypes() as IAnomalyTypeResponse;
                if (anomaliesResponse && anomaliesResponse.anomalies && anomaliesResponse.anomalies.length > 0) {
                    anomalyTypes = anomaliesResponse.anomalies as Array<IAnomalyType>;
                    setItem(Constants.ANOMALY_TYPES_COOKIE, JSON.stringify(anomalyTypes));
                }
            }
        } catch (error) {
            throw new Error(`Errore in caricamento Anomalies : ${(error as Error).message}`);
        }

        return anomalyTypes;
    },

    setAssistanceStatusesCookie: async (): Promise<Array<IAssistanceStatus>> => {
        //ASSISTANCE STATUSES
        var assStatuses: Array<IAssistanceStatus> = {} as Array<IAssistanceStatus>;
        try {
            let assStatusesCookie = getItem(Constants.ASSISTANCE_STATUSES_COOKIE);
            if (assStatusesCookie && assStatusesCookie !== '') {
                assStatuses = JSON.parse(assStatusesCookie) as Array<IAssistanceStatus>;
            }
            else {
                let assStatusResponse = await getAssistanceStatuses() as IAssistanceStatusResponse;
                if (assStatusResponse && assStatusResponse.assistances && assStatusResponse.assistances.length > 0) {
                    assStatuses = assStatusResponse.assistances as Array<IAssistanceStatus>;
                    setItem(Constants.ASSISTANCE_STATUSES_COOKIE, JSON.stringify(assStatuses));
                }
            }
        } catch (error) {
            throw new Error(`Errore in caricamento Assistances : ${(error as Error).message}`);
        }
        return assStatuses;
    },

    setCountryUsersCookie: async (countryCode: string, startDate?: Date, endDate?: Date): Promise<IUser[]> => {
        var users: IUser[] = [];
        try {
            let keySuffix = (startDate !== undefined && endDate !== undefined) ? `${moment(startDate).format("DDMMYYYY")}_${moment(endDate).format("DDMMYYYY")}` : "";
            debugger;
            let countryUsersCookie = getItem(keySuffix !== '' ? `${Constants.COUNTRY_USERS_COOKIE}_${keySuffix}_${countryCode}` : `${Constants.COUNTRY_USERS_COOKIE}_${countryCode}`);
            if (countryUsersCookie && countryUsersCookie !== '') {
                users = JSON.parse(countryUsersCookie) as IUser[];
            }
            else {
                const countryUsersResponse = await getUsers(countryCode !== 'all' ? countryCode : '', startDate, endDate);
                if (countryUsersResponse && countryUsersResponse.users.length > 0) {
                    users = countryUsersResponse.users.map((user) => {
                        return {
                            idUserSF: user.idUserSF,
                            fullName: user.fullName,
                            lastName: user.lastName,
                            firstName: user.firstName,
                            userName: user.userName,
                            email: user.email,
                            companyCode: user.companyCode,
                            companyCountry: user.companyCountry,
                            companyDescription: user.companyDescription,
                            companyLocation: user.companyLocation,
                            departmentCode: user.departmentCode,
                            departmentDescription: user.departmentDescription,
                            roleCode: user.roleCode,
                            roleDescription: user.roleDescription,
                            managerUserId: user.managerUserId,
                            dateOfBirth: user.dateOfBirth
                        } as IUser
                    }) as Array<IUser>;
                    setItem(keySuffix !== '' ? `${Constants.COUNTRY_USERS_COOKIE}_${keySuffix}_${countryCode}` : `${Constants.COUNTRY_USERS_COOKIE}_${countryCode}`, JSON.stringify(users));
                }
            }
        } catch (error) {
            throw new Error(`Errore in caricamento Assistances : ${(error as Error).message}`);
        }
        return users;
    },
    setUserContractsCookie: async (email: string): Promise<IEmployee[]> => {
        var userContracts: IEmployee[] = [];
        let userContractsCookie = getItem(Constants.USER_CONTRACTS_COOKIE);
        if (userContractsCookie && userContractsCookie !== '') {
            userContracts = JSON.parse(userContractsCookie) as IEmployee[];
            //Imposto il valore corretto per i campi data
            userContracts.forEach((contract: any, index: number) => {
                contract.createdDateTime = (contract.createdDateTime !== null && contract.createdDateTime !== undefined) ? new Date(contract.createdDateTime) : null;
                contract.startDate = (contract.startDate !== null && contract.startDate !== undefined) ? new Date(contract.startDate) : null;
                contract.endDate = (contract.endDate !== null && contract.endDate !== undefined) ? new Date(contract.endDate) : null;
                contract.holidayAmountStartDate = (contract.holidayAmountStartDate !== null && contract.holidayAmountStartDate !== undefined) ? new Date(contract.holidayAmountStartDate) : null;
                contract.hoursPerDay = moment.duration(contract.hoursPerDay);
                contract.hoursPerSaturday = moment.duration(contract.hoursPerSaturday);
            });
        }
        else {
            let userContractsResponse = await getUserContracts(email);
            if (userContractsResponse && userContractsResponse.length > 0) {
                userContracts = userContractsResponse as IEmployee[];
                setItem(Constants.USER_CONTRACTS_COOKIE, JSON.stringify(userContracts));
            }
        }
        return userContracts;
    },
    setTempEndOfWorkAction: (action?: ITimesheetAction) => {
        if (action !== undefined) {
            setItem(Constants.EOW_ACTION_COOKIE, JSON.stringify(action));
        } else {
            localStorage.removeItem(Constants.EOW_ACTION_COOKIE);
        }
    },
    setClearSessionValues: () => {
        setItem(Constants.CLEAR_SESSION_COOKIE, "true");
    },
    setUserActionsCookie: async (idUserSF: string): Promise<IActionTypeResponse> => {
        let result: IActionTypeResponse = {} as IActionTypeResponse;
        let userActionsCookie = getItem(Constants.USER_ACTIONS_COOKIE);
        if (userActionsCookie && userActionsCookie !== '') {
            result = JSON.parse(userActionsCookie) as IActionTypeResponse;
        } else {
            let userActionsResponse = await getSiaActionTypes(idUserSF, false);
            if (userActionsResponse && userActionsResponse.actions.length > 0) {
                result = userActionsResponse;
                setItem(Constants.USER_ACTIONS_COOKIE, JSON.stringify(userActionsResponse));
            }
        }
        return result;
    },
    setUserTypeCookie: async (debug: boolean): Promise<UserType> => {
        try {
            const userTypeCookie = getItem(Constants.USER_TYPE_COOKIE);
            if (userTypeCookie && userTypeCookie !== '') {
                //return JSON.parse(userTypeCookie) as UserType;
                const userType = getItem(Constants.USER_TYPE_COOKIE);
                if (userType && userType !== '') {
                    switch (userType) {
                        case "HR":
                            return UserType.HR
                        case "ADMIN":
                            return UserType.Admin
                        default:
                            return UserType.User
                    }
                }
                //Default
                return UserType.Unknown;
            }
            else {
                const roleResponse = await getUserRole();
                const role: UserType = roleResponse.status === 1 ? roleResponse.payload as UserType : UserType.Unknown;
                var ut = process.env.REACT_APP_SIA_DEBUG_USER_TYPE as string;
                const userType: UserType = debug ? (UserType as any)[`${ut as string}`] : role;
                setItem(Constants.USER_TYPE_COOKIE, userType);
                return role;
            }
        } catch (error) {
            throw new Error(`Errore recupero tipologia utente : ${(error as Error).message}`);
        }





    },
}

export const getApplicationCookies = {
    getStaffQueryDataCookie: (): Date => {
        let res: any = getItem('tmpDate');

        return res === null ? new Date(Date.parse('2000-01-01')) : new Date(res);
    },
    getActionTypesCookie: (): Array<IActionType> => {
        // ACTION TYPES
        var actionTypes: Array<IActionType> = [] as Array<IActionType>;
        let actionTypesCookie = getItem(Constants.ACTION_TYPES_COOKIE);
        if (actionTypesCookie && actionTypesCookie !== '') {
            actionTypes = JSON.parse(actionTypesCookie) as Array<IActionType>;
        }
        return actionTypes;
    },

    getOrgChartCookie: (): IOrgChartUser => {
        // ORG CHART
        var orgChart: IOrgChartUser = {} as IOrgChartUser;
        let orgChartCookie = getItem(Constants.ORG_CHART_COOKIE);
        if (orgChartCookie && orgChartCookie !== '') {
            orgChart = JSON.parse(orgChartCookie) as IOrgChartUser;
        }
        return orgChart;
    },

    getWorkingModesCookie: (): Array<IWorkingMode> => {
        var workingModes: Array<IWorkingMode> = [] as Array<IWorkingMode>;
        let workingModesCookie = getItem(Constants.WORKING_MODES_COOKIE);
        if (workingModesCookie && workingModesCookie !== '') {
            workingModes = JSON.parse(workingModesCookie) as Array<IWorkingMode>;
        }
        return workingModes;
    },

    getCurrentWorkingModeCookie: (): number | null => {
        // CURRENT WORKING MODE
        let idWorkingMode: number | null = null;
        let currentWorkingModeCookie = getItem(Constants.CURRENT_WORKING_MODE_COOKIE);
        if (currentWorkingModeCookie && currentWorkingModeCookie !== '') {
            idWorkingMode = currentWorkingModeCookie === "null" ? null : parseInt(currentWorkingModeCookie);
        }
        return idWorkingMode;
    },

    getSelectedJobCookie: (): number => {
        // EMPJOB SELECTED
        let id: number = 0;
        let cookie = getItem(Constants.EMPJOB_SELECTED_COOKIE);
        if (cookie && cookie !== '') {
            id = parseInt(cookie);
        }
        return id;
    },

    getFestivitiesCookie: (): Array<IFestivity> => {
        // FESTIVITIES
        var festivities: Array<IFestivity> = [] as Array<IFestivity>;
        let festivitiesCookie = getItem(Constants.FESTIVITIES_COOKIE);
        if (festivitiesCookie && festivitiesCookie !== '') {
            festivities = JSON.parse(festivitiesCookie) as Array<IFestivity>;
        }
        return festivities;
    },

    getAnomalyTypesCookie: (): Array<IAnomalyType> => {
        // ANOMALY TYPES
        var anomalyTypes: Array<IAnomalyType> = [] as Array<IAnomalyType>;
        let anomalyTypesCookie = getItem(Constants.ANOMALY_TYPES_COOKIE);
        if (anomalyTypesCookie && anomalyTypesCookie !== '') {
            anomalyTypes = JSON.parse(anomalyTypesCookie) as Array<IAnomalyType>;
        }
        return anomalyTypes;
    },

    getAssistanceStatusesCookie: (): Array<IAssistanceStatus> => {
        // ASSISTANCE STATUSES
        var assStatuses: Array<IAssistanceStatus> = [] as Array<IAssistanceStatus>;
        let assStatusesCookie = getItem(Constants.ASSISTANCE_STATUSES_COOKIE);
        if (assStatusesCookie && assStatusesCookie !== '') {
            assStatuses = JSON.parse(assStatusesCookie) as Array<IAssistanceStatus>;
        }
        return assStatuses;
    },

    getCountryUsersCookie: (countryCode: string): IUser[] => {
        let users: IUser[] = [];
        const countryUsers = getItem(`${Constants.COUNTRY_USERS_COOKIE}_${countryCode}`);
        if (countryUsers && countryUsers !== '') {
            users = JSON.parse(countryUsers) as IUser[];
        }
        return users;
    },

    getUserTypeCookie: (): UserType => {
        const userType = getItem(Constants.USER_TYPE_COOKIE);
        if (userType && userType !== '') {
            switch (userType) {
                case "HR":
                    return UserType.HR
                case "ADMIN":
                    return UserType.Admin
                default:
                    return UserType.User
            }
        }
        //Default User
        return UserType.User;
    },
    getTempEndOfWorkAction: (): ITimesheetAction | null => {
        var result = null;
        const value = getItem(Constants.EOW_ACTION_COOKIE);
        if (value && value !== '') {
            return JSON.parse(value) as ITimesheetAction;
        }
        return result;
    },
    getClearSessionValues: (): string => {
        const clearSession = getItem(Constants.CLEAR_SESSION_COOKIE);
        if (clearSession && clearSession !== '') {
            return clearSession;
        }
        return '';
    },
    getContractNotFoundCookie: (): string => {
        const cErrFound = getItem(Constants.CONTRACT_NOT_FOUND_COOKIE);
        if (cErrFound && cErrFound !== '') {
            return cErrFound;
        }
        return 'false';
    }
}